/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Deepak's Portfolio",
  description:
    "An experienced software engineer with expertise in Python, Django, with a proven track record of developing and leading projects, managing teams, and delivering high-quality software solutions. ",
  og: {
    title: "Deepak Kumar Portfolio",
    type: "website",
    url: "http://robinsdeepak.in",
  },
};

//Home Page
const greeting = {
  title: "Deepak Kumar",
  logo_name: "Deepak",
  nickname: "robinsdeepak",
  subTitle:
    "An experienced software engineer with expertise in Python, Django, with a proven track record of developing and leading projects, managing teams, and delivering high-quality software solutions. ",
  resumeLink:
    "https://docs.google.com/document/d/1H9i7PuBwdimm_AtOYvoX9dPnVr3CcPnaQkoFSLPhYyg/edit?usp=sharing",
  portfolio_repository: "https://github.com/robinsdeepak",
  githubProfile: "https://github.com/robinsdeepak",
};

const socialMediaLinks = [
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/robinsdeepak/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:robinsdeepak@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Github",
    link: "https://github.com/robinsdeepak",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "Octaprofile",
    link: "https://octoprofile.vercel.app/user?id=robinsdeepak",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#0000FF", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "Twitter",
    link: "https://twitter.com/robinsdeepak",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/robinsdeepak/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/robinsdeepak/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Backend Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Developing highly scalable production ready Web Applications and Mobile Applications for various use cases",
        "⚡ Proven records in leading projects, managing teams, and delivering high-quality software solutions.",
        "⚡ Creating application backend in Python, Django & Flask",
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "logos:python",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "vscode-icons:file-type-django",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Flask",
          fontAwesomeClassname: "devicon:flask-wordmark",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "devicon:postgresql-wordmark",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "logos:mysql",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        /*
        {
          skillName: "HTML",
          fontAwesomeClassname: "logos:html-5",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "CSS",
          fontAwesomeClassname: "akar-icons:css-fill",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "logos:javascript",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "devicon:flutter",
          style: {
            color: "#2C8EBB",
          },
        },
        */
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/robinsdeepak/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/robinsdeepak",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/robinsdeepak",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/robinsdeepak",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@robinsdeepak",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/robinsdeepak",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Bakhtiyarpur College of Engineering, Patna, India",
      subtitle: "B.Tech",
      logo_path: "logo-bced-f.png",
      alt_name: "BCE Bakhtiyarpur",
      duration: "2016 - 2020",
      descriptions: [
        // "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        // "⚡ Apart from this, I have done courses on Deep Learning, Data Science, Cloud Computing and Full Stack Development.",
        // "⚡ I was selected for Merit cum Means Scholarship which is given to top 10% of students in college. I have received award from respected director for consistently best performance in academics.",
      ],
      website_link: "https://bcebakhtiyarpur.org/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Advanced Python",
      subtitle: "- Joe Marini",
      logo_path: "linkedin_learning_logo.svg",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/725a3422ff9aa71b20e5fa9ba5d8bcb4ce44fec673242b7774c1f3c8ecd2857d",
      alt_name: "LinkedIn Learning",
      color_code: "#00000099",
    },
    {
      title: "Django Core | A Reference Guide to Core Django Concepts",
      subtitle: "- Justin Mitchel",
      logo_path: "udemy_logo.svg",
      certificate_link:
        "https://udemy-certificate.s3.amazonaws.com/pdf/UC-07G9BG6S.pdf",
      alt_name: "Udemy",
      color_code: "#00000099",
    },
    {
      title: "Data Science Training",
      subtitle: "- Internshala",
      logo_path: "Internshala_logo.avif",
      certificate_link:
        "https://drive.google.com/file/d/1ImoJbeM-Qw5QoPgM2vxwhKNwgebAPp0K/view?usp=sharing",
      alt_name: "Internshala",
      color_code: "#00000099",
    },
    {
      title:
        "Improving Deep Neural Networks: Hyperparameter Tuning, Regularization and Optimization",
      subtitle: "- Andrew Ng",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/CFWRPKR8B5DZ",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "Neural Networks and Deep Learning",
      subtitle: "- Andrew Ng",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/GRTWERLKN6GK",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Works & Internships",
  description: "",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Software Engineer",
          company: "Goldman Sachs",
          company_url: "https://www.linkedin.com/company/goldman-sachs/about/",
          logo_path: "goldman_sachs_logo.jpeg",
          duration: "Aug 2023 - Present",
          location: "Benguluru, India",
          description: "",
          color: "#0879bf",
        },
        {
          title: "Lead Consultant",
          company: "Genpact",
          company_url: "https://www.linkedin.com/company/genpact/about/",
          logo_path: "genpact_logo.jpeg",
          duration: "Aug 2023 - Present",
          location: "Benguluru, India",
          description: "",
          color: "#0879bf",
        },
        {
          title: "Team Lead",
          company: "Headrun Technologies",
          company_url:
            "https://www.linkedin.com/company/headrun-technologies-pvt-ltd/about/",
          logo_path: "headrun.svg",
          duration: "Sept 2022 - July 2023",
          location: "Benguluru, India",
          description: "",
          color: "#0879bf",
        },
        {
          title: "Software Engineer",
          company: "Headrun Technologies",
          company_url:
            "https://www.linkedin.com/company/headrun-technologies-pvt-ltd/about/",
          logo_path: "headrun.svg",
          duration: "May 2021 - Sept 2022",
          location: "Benguluru, India",
          description: "",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Software Engineer Intern",
          company: "IDeepeners Private Ltd",
          company_url: "https://www.linkedin.com/company/ideepeners/about/",
          logo_path: "ideepeners_logo.jpeg",
          duration: "Oct 2020 - Dec 2020",
          location: "Jaipur, India",
          description: "",
          color: "#000000",
        },
        {
          title: "Research Intern",
          company: "VEStellaLab",
          company_url: "https://www.linkedin.com/company/vestellalab/about/",
          logo_path: "vestella_logo.jpeg",
          duration: "Jan 2020 - July 2020",
          location: "Seoul, South Korea",
          description: "",
          color: "#000000",
        },
        {
          title: "Research Intern",
          company: "IIT Patna",
          company_url:
            "https://www.linkedin.com/school/indian-institute-of-technology-patna/about/",
          logo_path: "iitp_logo.svg",
          duration: "May 2019 - June 2019",
          location: "Patna, India",
          description: "",
          color: "#000000",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    // {
    //   id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
    //   name: "Artificial Intelligence Paper",
    //   createdAt: "2020-03-06T16:26:54Z",
    //   description: "Paper Written on Artificial Intelligence published in xyz ",
    //   url:
    //     "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    // }
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "deepak_round.png",
    description: "",
  },
  blogSection: {
    title: "",
    subtitle: "",
    link: "https://robinsdeepak.in",
    // avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Bengaluru, KN, India",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/rNrYraoTZcGk3PRX7",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
